var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"col-lg-8 col-12"},[(_vm.tasksLoading)?_c('div',[_c('lottie-animation',{attrs:{"animationData":_vm.animationData}})],1):_c('div',{staticClass:"mt-4"},[(_vm.items.length === 5)?_c('div',{staticClass:"empty-state"},[_c('img',{attrs:{"src":"assets/images/task-icon.png"}}),_vm._m(0)]):_c('div',_vm._l((_vm.items),function(item,index){return _c('div',{key:index},[_c('div',{style:({ marginBottom: '-26px' })},[_c('div',{staticClass:"image-container"},[_c('img',{staticClass:"image",attrs:{"src":item.taskImage,"width":"100%","height":"100%"}})]),_c('div',{staticClass:"description-container"},[_c('div',{staticClass:"description"},[_c('span',{staticClass:"item-title"},[_c('bold',[_vm._v(_vm._s(item.name))])],1),_c('p',{staticClass:"content-desc",domProps:{"innerHTML":_vm._s(item.description)}})]),_c('div',{style:({ alignSelf: 'flex-end', marginBottom: '16px' })},[_c('div',{staticClass:"icon-container"},[(!item.isCelebration)?[(item.taskType == 'ProfileTask')?[_c('a',{staticClass:"content-title",attrs:{"href":"#"},on:{"click":function($event){_vm.$router.push({
                            name: 'user.surveys.mobil',
                            params: {
                              name: _vm.convertToKebabCase(item.name),
                              id: item.id,
                              questionIds: item.questionIds,
                              code: item.code,
                            },
                          })}}},[_c('img',{staticClass:"icon-image",attrs:{"src":"/images/mobile/right-icon.png","width":"8px","height":"10px"}})])]:(item.taskType == 'CRMCampaign')?[_c('a',{staticClass:"content-title",attrs:{"href":"#"},on:{"click":function($event){return _vm.$router.push({
                            name: 'user.campaign',
                            params: { id: item.id },
                          })}}},[_c('img',{staticClass:"icon-image",attrs:{"src":"/images/mobile/right-icon.png","width":"8px","height":"10px"}})])]:(item.taskType == 'ExternalSurvey')?[_c('a',{staticClass:"content-title",attrs:{"href":"#"},on:{"click":function($event){return _vm.redirectTo(item)}}},[_c('img',{staticClass:"icon-image",attrs:{"src":"/images/mobile/right-icon.png","width":"8px","height":"10px"}})])]:(item.taskType == 'LiveSurvey')?[_c('a',{staticClass:"content-title",attrs:{"href":"#"},on:{"click":function($event){return _vm.redirectTo(item, 'live')}}},[_c('img',{staticClass:"icon-image",attrs:{"src":"/images/mobile/right-icon.png","width":"8px","height":"10px"}})])]:(item.taskType == 'CrmMiniAnket')?[_c('a',{staticClass:"content-title",attrs:{"href":item.urlLink}},[_c('img',{staticClass:"icon-image",attrs:{"src":"/images/mobile/right-icon.png","width":"8px","height":"10px"}}),_vm._v(" >")])]:_vm._e()]:_vm._e()],2)])])])])}),0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"empty-content"},[_vm._v(" Henüz bir görevin yok. "),_c('br'),_vm._v("Beklemede Kal. ")])
}]

export { render, staticRenderFns }